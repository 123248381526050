<template>
    <div>
        <v-row>
            <v-col cols="12" md="8" offset-md="2">
                <v-list nav color="transparent">
                    <v-list-item-group disabled v-model="invitationType" color="primary">
                        <v-row justify="center" align="center">
                            <v-col cols="12" sm="6">
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="overline mt-0 mb-1 d-flex justify-center">OPTION 1</div>
                                        <div class="d-flex justify-center">
                                            <div class="subtitle-2 secondary--text text-uppercase">user invitation</div>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="overline mt-0 mb-1 d-flex justify-center">OPTION 2</div>
                                        <div class="d-flex justify-center">
                                            <div class="subtitle-2 secondary--text text-uppercase">administrator invitation</div>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <div v-if="invitationType !== undefined">
            <div
                v-if="invitationType === mainInvitationOptions.INSTANCE_USER_INVITATION && currentSpaceType === spaceTypes.EDUCATION_SPACE"
                class="mt-10 d-flex justify-space-around align-center">
                <v-radio-group row v-model="instanceInvitationType">
                    <div v-for="(instanceInvitationOption, index) in availableInstanceOptions" :key="index" class="d-flex justify-space-between">
                        <div class="d-flex align-center flex-column">
                            <v-radio
                                class="mt-3 caption"
                                color="primary"
                                :value="instanceInvitationOption.value"
                                :label="instanceInvitationOption.radioLabel"></v-radio>
                            <add-instance-dialog
                                v-if="instanceInvitationOption.radioSubHeader"
                                @inviteUsers="updateDataForTargetInstance({ instanceName: $event.instanceName })"
                                @dialogIsOpen="moveToInviteToExistingInstanceTab($event.value)">
                                <v-hover v-slot:default="{ hover }">
                                    <span
                                        :class="[hover ? 'secondary--text' : 'secondary--text', 'font-weight-bold', 'caption']"
                                        :style="hover ? 'cursor: pointer;' : ''">
                                        {{ instanceInvitationOption.radioSubHeader }}
                                    </span>
                                </v-hover>
                            </add-instance-dialog>
                        </div>
                    </div>
                </v-radio-group>
            </div>
            <div class="mt-5">
                <component
                    v-if="displayedComponent === 'TheInstanceInvite'"
                    :dataForInstanceToInviteTo="dataForInstanceInvitation"
                    :is="'TheInstanceInvite'"></component>
                <component v-else :is="displayedComponent"></component>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const TheInstanceInvite = () => import('@/modules/instance/components/TheInstanceInvite')
const TheSpaceInviteAdmin = () => import('../components/TheSpaceInviteAdmin')
const TheSpaceInviteFromSnapshot = () => import('../components/TheSpaceInviteFromSnapshot')
const AddInstanceDialog = () => import('@/modules/space/components/TheSpaceAddInstanceDialog')
const instanceInvitationsOptions = {
    INVITE_NEW_MEMBERS: 'inviteNewMembers',
    INVITE_TO_EXISTING_INSTANCE: 'inviteToExistingInstance'
}
export default {
    components: { TheInstanceInvite, TheSpaceInviteAdmin, TheSpaceInviteFromSnapshot, AddInstanceDialog },
    mixins: [enumsData],
    props: {
        dataForInstanceToInviteTo: { type: Object, default: null }
    },
    data() {
        return {
            invitationType: 0,
            instanceInvitationType: instanceInvitationsOptions.INVITE_NEW_MEMBERS,
            mainInvitationOptions: {
                SPACE_ADMIN_INVITATION: 1,
                INSTANCE_USER_INVITATION: 0
            },
            isAddInstanceDialogOpen: false,
            dataForInstanceInvitation: null
        }
    },
    computed: {
        ...mapGetters('spaceStore', ['currentSpaceType']),
        displayedComponent() {
            if (this.invitationType === this.mainInvitationOptions.SPACE_ADMIN_INVITATION) {
                return 'TheSpaceInviteAdmin'
            } else if (
                this.invitationType === this.mainInvitationOptions.INSTANCE_USER_INVITATION &&
                this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE &&
                this.instanceInvitationType === instanceInvitationsOptions.INVITE_NEW_MEMBERS
            ) {
                return 'TheSpaceInviteFromSnapshot'
            }
            return 'TheInstanceInvite'
        },
        availableInstanceOptions() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return [
                    { radioLabel: 'Invite to individual instances', radioSubHeader: '', value: instanceInvitationsOptions.INVITE_NEW_MEMBERS },
                    {
                        radioLabel: 'Invite to an existing shared instance',
                        radioSubHeader: '(you can create a shared instance here)',
                        value: instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE
                    }
                ]
            } else {
                return [{ radioLabel: 'Invite to an existing instance', value: instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE }]
            }
        }
    },
    methods: {
        moveToInviteToExistingInstanceTab: function (dialogOpen) {
            if (dialogOpen && this.instanceInvitationType !== instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE) {
                this.instanceInvitationType = instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE
            }
        },
        updateDataForTargetInstance: function (newData) {
            this.dataForInstanceInvitation = newData
        }
    },
    mounted() {
        if (this.$props.dataForInstanceToInviteTo !== null) {
            this.instanceInvitationType = instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE
            this.dataForInstanceInvitation = this.$props.dataForInstanceToInviteTo
        }
    },
    watch: {
        dataForInstanceToInviteTo: function (nextVal, preVal) {
            if (nextVal !== preVal || (nextVal !== null && this.instanceInvitationType !== instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE)) {
                this.instanceInvitationType = instanceInvitationsOptions.INVITE_TO_EXISTING_INSTANCE
                this.dataForInstanceInvitation = this.$props.dataForInstanceToInviteTo
            }
        }
    }
}
</script>
